


























































































































export default {

  data() {
    return {
      loginStatusChecked: false,
      form: {
        email: '',
        password: ''
      },
      registerForm: {
        name: '',
        surname: '',
        email: '',
        password: '',
        gdpr: null
      }
    }
  },

  async created() {
    if (this.$store.getters['wAuth/isLoggedIn']) {
      await this.redirectAfterLogin()
    }

    this.loginStatusChecked = true
  },
  
  methods: {
    async redirectAfterLogin() {
      if (this.$route.query?.redirectToAfterLogin) {
        return this.$router.push({ path: this.$route.query?.redirectToAfterLogin })
      }

      return this.$router.push({name: 'Tests'})
    },

    validateState(ref) {
      if (
          this.veeFields[ref] &&
          (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    
    async register(e) {
      e.preventDefault()
      const results = Promise.all([
        this.$validator.validate('Meno'),
        this.$validator.validate('Priezvisko'),
        this.$validator.validate('Email2'),
        this.$validator.validate('Heslo2'),
        this.$validator.validate('GDPR')
      ])

      const isValid = (await results).every(isValid => isValid)

      if (isValid) {
        try {
          const credentials = {
            name: this.registerForm.name,
            surname: this.registerForm.surname,
            email: this.registerForm.email,
            password: this.registerForm.password,
            role: 'teacher'
          }
          await this.$store.dispatch('wAuth/signup', credentials)
          this.$bvToast.toast(
            'Verifikujte prosím váš účet kliknutím na odkaz v email, ktorý sme Vám odoslali.',
            {
              title: 'Registrácia bola úspešná!',
              variant: 'a-success',
              toaster: 'b-toaster-top-center',
              solid: true
            }
          )
        } catch (err) {
          this.$wToast.error(err)
        }
      }
    },

    async login() {
      const results = Promise.all([
        this.$validator.validate('Email'),
        this.$validator.validate('Heslo')
      ])

      const isValid = (await results).every(isValid => isValid)

      if (isValid) {
        try {
          const credentials = {
            login: this.form.email,
            password: this.form.password,
            role: 'teacher'
          }
          await this.$store.dispatch('wAuth/login', credentials)
          await this.redirectAfterLogin()
        } catch(err) {
          this.$wToast.error(err)
        }
      }
    }
  }
}
